import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';
import { degToRad, raDecToV3, radii } from './utils';

export const Grid: React.FC = () => {
	/** Radians */
	const step = degToRad(10);

	const decLines = _.range(-80, 81, 10).map(dec => {
		const position = raDecToV3(0, dec).multiplyScalar(radii.grid);

		return (
			<mesh
				key={`dec-${dec}`}
				position={new THREE.Vector3(0, position.y, 0)}
			>
				<cylinderGeometry
					args={[position.z, position.z, .1, 100, undefined, true]}
				/>
				<meshBasicMaterial
					color="cyan"
					side={THREE.BackSide}
				/>
			</mesh>
		);
	});

	const raLines = _.range(0, Math.PI, step).map(ra => {
		return (
			<mesh
				key={`ra-${ra}`}
				position={new THREE.Vector3(0, 0, 0)}
				rotation={new THREE.Euler(Math.PI / 2, 0, ra)}
			>
				<cylinderGeometry
					args={[radii.grid, radii.grid, .1, 100, undefined, true]}
				/>
				<meshBasicMaterial
					color="cyan"
					side={THREE.BackSide}
				/>
			</mesh>
		);
	});

	return (
		<>
			{decLines}
			{raLines}
		</>
	);
};
