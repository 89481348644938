import { useThree } from '@react-three/fiber';
import yalebsc from './stars-processing/yalebsc.json';
import { raDecToV3, radii } from './utils';

export const Stars = () => {
	(window as any).yalebsc = yalebsc;

	return (<>
		{yalebsc
			.map((s, i) => {
				const v3 = raDecToV3(s.ra, s.dec).multiplyScalar(radii.stars);

				const opacity = (() => {
					/** [-1.4, 8.2] */
					const m = s.mag;

					if (m <= 3.5)
						return 1;
					else if (m <= 5.5)
						return .3;
					else
						return .1
				})();

				return (
					<mesh
						key={i}
						position={v3}
					>
						<sphereGeometry
							args={[1, 10, 10]}
						/>
						<meshBasicMaterial
							color="white"
							opacity={opacity}
							transparent
						/>
					</mesh>
				);
			})}
	</>);
};
