import * as THREE from 'three';

export const degToRad = (deg: number) => {
	return deg / 180 * Math.PI;
}

/**
 * Converts an RA/DEC pair into a `Vector3` of length 1.
 * 
 * N -> (0, 0, 1)
 * E -> (1, 0, 0)
 *
 * @param ra in degrees [0, 360]
 * @param dec in degrees [-90, 90]
 */
export const raDecToV3 = (ra: number, dec: number) => {
	/** [0, pi], with 0 pointing up */
	const inclination = degToRad(90 - dec);
	/** [0, 2pi], with N being pi/2 */
	const azimuth = degToRad(ra + 90);

	const v3 = new THREE.Vector3(
		-Math.sin(inclination) * Math.cos(azimuth),
		Math.cos(inclination),
		Math.sin(inclination) * Math.sin(azimuth)
	);
	return v3;
}

export const radii = {
	images: 99,
	stars: 900,
	grid: 101
};
