import { Canvas } from '@react-three/fiber';
import styles from './App.module.scss';
import { images } from './data/images';
import { Grid } from './Grid';
import { Stars } from './Stars';
import { Image } from './Image';
import { CameraControls } from './CameraControls';

// +X -> right
// +Y -> up
// +Z -> out

export const App = () => {
	return (
		<div className={styles.root}>
			<Canvas
				camera={{
					near: 0.1,
					far: 1000,
					fov: 60,
					position: [0, 0, 0]
				}}
			>

				<Grid />

				<Stars />

				{images.map(i => (
					<Image image={i} key={i.name} />
				))}

				<axesHelper args={[100]} />

				<CameraControls />
			</Canvas>
		</div>
	);
};
