export interface AstroImage {
	dec: number;
	filename: string;
	/** In deg */
	height: number;
	name: string;
	/** In deg E of N */
	orientation: number;
	ra: number;
	/** In deg */
	width: number;
}

export const images: AstroImage[] = [
	{
		dec: -5.506,
		filename: require('../images/orion.jpg'),
		height: 2.29,
		name: 'Orion Nebula',
		orientation: 275.3,
		ra: 83.782,
		width: 2.31,
	},
	{
		dec: 31.316,
		filename: require('../images/veil.jpg'),
		height: 4.29,
		name: 'Veil Nebula',
		orientation: 279.9,
		ra: 313.294,
		width: 2.93,
	},
	{
		dec: 36.313,
		filename: require('../images/california.jpg'),
		height: 2.32,
		name: 'California Nebula',
		orientation: 103.5,
		ra: 60.2,
		width: 2.32
	},
	{
		dec: 41.267,
		filename: require('../images/andromeda.jpg'),
		height: 2.32,
		name: 'Andromeda Galaxy',
		orientation: 6.8,
		ra: 10.701,
		width: 2.32
	}
];
