import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';

export const CameraControls = () => {
	const state = useThree();

	(window as any).threestate = state;
	useEffect(
		() => {
			console.log('window.threestate is available');
			console.log('For now, janky controls are arrow keys and mouse scroll')
		},
		[]
	);

	useEffect(
		() => {
			const delta = .01;
			const onKeyDown = (e: KeyboardEvent) => {
				switch (e.key) {
					case 'ArrowRight':
						state.camera.rotateY(-delta);
						break;

					case 'ArrowLeft':
						state.camera.rotateY(delta);
						break;

					case 'ArrowUp':
						state.camera.rotateX(delta);
						break;

					case 'ArrowDown':
						state.camera.rotateX(-delta);
						break;
				}
			};

			const onWheel = (e: WheelEvent) => {
				const cam = state.camera as any;

				const delta = .1;
				const multiplier = e.deltaY < 0 ? (1 - delta) : (1 + delta);
				cam.setFocalLength(cam.getFocalLength() * multiplier);
			};

			window.addEventListener('keydown', onKeyDown);
			window.addEventListener('wheel', onWheel);

			return () => {
				window.removeEventListener('keydown', onKeyDown);
				window.removeEventListener('wheel', onWheel);
			}
		},
		[state]
	);

	return null;
};
