import { useThree } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { AstroImage } from './data/images';
import { degToRad, raDecToV3, radii } from './utils';

interface Props {
	image: AstroImage;
}

export const Image: React.FC<Props> = ({ image }) => {
	const textureLoader = useMemo(() => new THREE.TextureLoader(), []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const texture = useMemo(() => textureLoader.load(image.filename), []);

	const centerPos = raDecToV3(image.ra, image.dec).multiplyScalar(radii.images);

	const meshRef = useRef<THREE.Mesh>(null);
	// rotate into place: face the origin and orient image up to north
	useEffect(
		() => {
			const up = new THREE.Vector3(0, 1, 0);
			up.applyAxisAngle(centerPos.normalize(), degToRad(image.orientation));
			meshRef.current!.up = up;
			meshRef.current!.lookAt(0, 0, 0);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const state = useThree();
	const functionName = `goto${image.name.replace(/ /g, '')}`;
	console.log(`window.${`goto${image.name.replace(/ /g, '')}`} is now available`);
	(window as any)[functionName] = () => {
		state.camera.lookAt(centerPos);
	};

	/** top left, top right, bottom right, bottom left */
	const corners = [
		raDecToV3(image.ra - image.width / 2, image.dec + image.height / 2),
		raDecToV3(image.ra + image.width / 2, image.dec + image.height / 2),
		raDecToV3(image.ra + image.width / 2, image.dec - image.height / 2),
	].map(v3 => v3.multiplyScalar(radii.images));
	const width = corners[0].distanceTo(corners[1]);
	// const height = corners[1].distanceTo(corners[2]);
	// TODO: why does the above calculation not always produce the proper aspect ratio?
	const height = width * image.height / image.width;

	return (
		<mesh
			position={centerPos}
			ref={meshRef}
		>
			<planeGeometry
				args={[width, height]}
			/>

			<meshBasicMaterial
				side={THREE.DoubleSide}
				map={texture}
			/>
		</mesh>
	);
};
